const listDetailCtn = [
  {
    id:2,
    text: `Votre cloud , votre choix. Relier votre cloud permet à GetGaaS de gérer le provisionnement et l'orchestration des serveurs pour que vous puissiez vous concentrer sur votre projet.`,
    title: "3. Se connecter avec un fournisseur de services cloud",
  },
  {
    id:3,
    text: `Déploiement souple et sans douleur. Une intégration continue ? Des pipelines de développement / AQ / production séparés ? Des rollbacks instantanés ? Oui, Oui, Oui Oui. Osez les déploiements le vendredi soir!`,
    title: "4. Déploiement en temps réel!",
  },
]

export default listDetailCtn