import React from "react"
import Layout from "../components/decorate/Layout"
import "../scss/pages/preSignin.scss"
import listDetailCtn5 from "../utils/main/listDetailCtn5"
import listDetailCtn6 from "../utils/main/listDetailCtn6"
import listDetailCtn7 from "../utils/main/listDetailCtn7"
import StripeEight from "../components/main/StripeEight"
import plateform1 from "../images/bandeau1/Bateau-En-Tete-Accueil.png"
import plateform from "../images/bandeau2/compatibilite_assuree.png"
import multicloud from "../images/bandeau1/Plateforme-En-Tete-Accueil.png"

function howItWorks() {

  return (
    <Layout>
      <div className="bandeau zone4__header marged">
        <div className="zone4__header__text ">
          <span className="bigText">GetCaaS vous donne <span>le pouvoir</span> de gérer une infrastructure complexe sans changer votre <span>workflow</span> de développement ni migrer vers une nouvelle plateforme.</span>
        </div>
        <div className="zone4 marged">
          <div className="zone4__imageCtn">
            <img src={plateform1} title="plateform" alt="multicloud système"/>
          </div>
          <div className="zone4__slideshowSlider" >
            <div className="zone4__slideshowSlider__ctn">
              <div>
                {
                  listDetailCtn5.map((el, idx) => {
                    return (
                      <div className="p-5">
                        <div className="m-3">
                          <span className="color1">{listDetailCtn5[idx].title}</span>
                        </div>
                        <div>
                          <p>{listDetailCtn5[idx].text}</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
        <div className="zone4 marged">
          <div className="zone4__slideshowSlider" >
            <div className="zone4__slideshowSlider__ctn">
              <div>
                {
                  listDetailCtn7.map((el, idx) => {
                    return (
                      <div className="p-5">
                        <div className="m-3">
                          <span className="color1">{listDetailCtn7[idx].title}</span>
                        </div>
                        <div>
                          <p>{listDetailCtn7[idx].text}</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="zone4__imageCtn">
            <img src={plateform} title="plateform" alt="multicloud système"/>
          </div>
        </div>
        <div className="zone4 marged">
          <div className="zone4__imageCtn">
            <img src={multicloud} title="plateform" alt="multicloud système"/>
          </div>
          <div className="zone4__slideshowSlider" >
            <div className="zone4__slideshowSlider__ctn">
              <div>
                {
                  listDetailCtn6.map((el, idx) => {
                    return (
                      <div className="p-5">
                        <div className="m-3">
                          <span className="color1">{listDetailCtn6[idx].title}</span>
                        </div>
                        <div>
                          <p>{listDetailCtn6[idx].text}</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <StripeEight/>
    </Layout>
  )
}

export default howItWorks