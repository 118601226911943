const listDetailCtn = [
  {
    id:0,
    text: `Concentrez-vous sur votre entreprise. Votre temps devrait être consacré à la croissance de votre entreprise.   Pas de gestion des serveurs. Pas d'orchestration des infrastructures.`,
    title: "1. Coder votre application",
  },
  {
    id:1,
    text: `Création de conteneur simplifié. Décrivez ce que votre projet doit faire et utilisez l'outil getcaas pour générer vos configurations avec Docker.`,
    title: "2. Conteneuriser avec Docker",
  },
]

export default listDetailCtn